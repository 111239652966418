import _ from 'lodash';
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {hsl} from 'd3';

import {labelize} from '@shared/utils/labelize';
import {stripTags} from '@shared/utils/strip-tags';

import {config} from '../../config';
import {
  Adapter,
  AdapterBase,
  AdapterConfig,
  AdapterInfo,
  AdapterOperations,
  AdapterParams,
  Adapters,
  AdaptersNames,
  CommunityCertifiedAdapter,
  Operation,
  OutputSchemaField,
  RawOperation,
  SchemaField,
} from '../types';
import {ThemeKey} from '../theming/theme.service';
import {isDeprecatedAdapter} from '../modules/adapter-lists/adapter-lists-helpers';

import {CustomAdaptersService} from './custom-adapters.service';
import {AuthUser} from './auth-user';
import {Integrations} from './integrations';
import {PicklistRegisterOptions, PicklistService} from './picklist.service';

interface LoadAdapterOptions extends AdapterParams {
  force?: boolean;
}

const LIGHT_THEME_LIGHTNESS_THRESHOLD = 0.9;
const DARK_THEME_LIGHTNESS_THRESHOLD = 0.16;

export const FRONTEND_ADAPTER_NAMES: AdaptersNames = [
  'workato',
  'foreach',
  'repeat',
  'catch',
  'workato_apim_request',
  'insights_column',
];
export const BUILT_IN_ADAPTERS_NAMES: AdaptersNames = ['workato', 'foreach', 'repeat', 'catch', 'utility'];
export const BOT_ADAPTERS_NAMES = ['slack_bot', 'teams_bot'] as const;
export const BOT_ADAPTERS_LABELS_MAP = new Map<BotAdapterName, string>([
  ['slack_bot', 'Slack'],
  ['teams_bot', 'MS Teams'],
]);
export type BotAdapterName = (typeof BOT_ADAPTERS_NAMES)[number];

@Injectable({
  providedIn: 'root',
})
export class AdaptersService implements OnDestroy {
  adaptersChanged$: Observable<AdaptersNames>;

  protected adapters: Adapters = Object.assign(config.providers || {}, {
    workato: {
      name: 'workato',
      title: $localize`Properties`,
    },
    foreach: {
      name: 'foreach',
      title: $localize`Foreach`,
    },
    repeat: {
      name: 'repeat',
      title: $localize`Repeat`,
    },
    catch: {
      name: 'catch',
      title: $localize`On error`,
    },
  });

  private disabledApps: Set<string>;
  private adaptersLoadPromises = new Map<Adapter['name'], Promise<Adapters>>();
  private customAdaptersLoadPromise: Promise<void> | null = null;
  private customAdaptersInfoLoadPromises = new Map<Adapter['name'], Promise<AdapterInfo[]>>();
  private upcomingAdaptersLoadPromise: Promise<void> | null = null;
  private communityAdaptersLoadPromise: Promise<void> | null = null;
  private customAdaptersStyleElem?: HTMLStyleElement;
  private otherAdaptersStyleElem?: HTMLStyleElement;
  private adaptersChanged = new Subject<AdaptersNames>();

  constructor(
    private authUser: AuthUser,
    private integrations: Integrations,
    private picklistService: PicklistService,
    private customAdapters: CustomAdaptersService,
  ) {
    this.disabledApps = new Set(this.authUser.disabled_apps);

    this.adaptersChanged$ = this.adaptersChanged.asObservable();
  }

  ngOnDestroy() {
    this.customAdaptersStyleElem?.remove();
    this.otherAdaptersStyleElem?.remove();
  }

  get hasOemDisabledApps(): boolean {
    return Boolean(this.disabledApps.size);
  }

  get categories(): string[] {
    return this.getCategories();
  }

  get availableCategories(): string[] {
    const availableAdapters = Object.values(this.list()).filter(adapter => !this.oemDisabled(adapter.name));

    return this.getCategories(availableAdapters);
  }

  get httpAdapterAvailable(): boolean {
    return !this.oemDisabled('rest') || !this.oemDisabled('rest_secondary');
  }

  list(): Adapters {
    return this.adapters;
  }

  system(): Array<Adapter['name']> {
    return config.system_providers as Array<Adapter['name']>;
  }

  get(name: Adapter['name']): Adapter {
    return this.adapters[name];
  }

  has(name: Adapter['name']): boolean {
    return _.has(this.adapters, name);
  }

  restricted(name: string): boolean {
    const adapter = this.get(name);

    return Boolean(
      adapter &&
        adapter.release_type !== 'sdk' &&
        adapter.release_type !== 'organization_sdk' &&
        this.authUser.authenticated &&
        adapter.required_feature &&
        // todo - check
        !this.authUser.hasPrivilege(adapter.required_feature, null) &&
        !this.authUser.hasAdHocAdapter(name),
    );
  }

  delisted(name: string): boolean {
    const adapter = this.get(name);

    return Boolean(adapter.delist_excluded) && (this.restricted(adapter.name) || !this.authUser.authenticated);
  }

  isCustomAdapter(name: string): boolean {
    return this.get(name).build_type === 'custom';
  }

  oemDisabled(name: string): boolean {
    return this.disabledApps.has(name);
  }

  oemShared(name: string): boolean {
    return this.get(name)?.release_type === 'organization_sdk';
  }

  /**
   * Loads any adapter(s) (standard and custom), including triggers and actions in config.
   * (latest published version, if current user is owner and staging param is used)
   */
  async load(adapterName: string | string[], opts: LoadAdapterOptions = {}): Promise<void> {
    const adapterNames = _(adapterName).castArray().compact().uniq().value();

    const adaptersToLoad = adapterNames.filter(
      name =>
        // Skipping already loading/loaded adapters
        !this.adaptersLoadPromises.has(name) || opts.force,
    );

    if (adaptersToLoad.length) {
      const loadPromise = this.loadAdaptersMeta(adaptersToLoad, opts.force, _.omit(opts, 'force'));

      _.forEach(adaptersToLoad, name => this.adaptersLoadPromises.set(name, loadPromise));
    }

    await Promise.all([...new Set(adapterNames.map(name => this.adaptersLoadPromises.get(name)))]);

    this.adaptersChanged.next(adapterNames);
  }

  /**
   * Loads all custom published adapters of current user, configs without trigger and actions
   */
  async loadCustomAdapters(): Promise<void> {
    if (!this.authUser.authenticated || !this.authUser.hasPrivilege('custom_adapter_use_in_recipes')) {
      return;
    }

    if (!this.customAdaptersLoadPromise) {
      this.customAdaptersLoadPromise = this.customAdapters
        .getConfigs()
        .then(adaptersInfo => this.bulkRegister(adaptersInfo))
        .catch(err => {
          this.customAdaptersLoadPromise = null;
          throw err;
        });
    }

    await this.customAdaptersLoadPromise;
  }

  /**
   * Loads any custom adapter(s), with basic config (logo + title)
   */
  async loadCustomAdaptersInfo(names: string | string[]): Promise<void> {
    const adapterNames = _(names).castArray().compact().uniq().value();

    const customAdaptersToLoad = adapterNames.filter(
      name =>
        // Skip already loading/loaded adapters
        !this.has(name) && !this.customAdaptersInfoLoadPromises.has(name),
    );

    if (customAdaptersToLoad.length) {
      const loadPromise = this.customAdapters.getInfo(customAdaptersToLoad).then(adaptersInfo => {
        this.bulkRegister(adaptersInfo);

        return adaptersInfo;
      });

      customAdaptersToLoad.forEach(name => this.customAdaptersInfoLoadPromises.set(name, loadPromise));
    }

    await Promise.all([...new Set(adapterNames.map(name => this.customAdaptersInfoLoadPromises.get(name)))]);
  }

  async loadUpcomingAdapters(): Promise<void> {
    if (!this.upcomingAdaptersLoadPromise) {
      this.upcomingAdaptersLoadPromise = this.integrations
        .getUpcoming()
        .then(adaptersInfo => this.bulkRegister(adaptersInfo))
        .catch(err => {
          this.upcomingAdaptersLoadPromise = null;
          throw err;
        });
    }

    await this.upcomingAdaptersLoadPromise;
  }

  async loadCommunityAdapters(): Promise<void> {
    if (!this.communityAdaptersLoadPromise) {
      this.communityAdaptersLoadPromise = this.integrations
        .getCommunity()
        .then(adaptersInfo => this.bulkRegister(adaptersInfo))
        .catch(err => {
          this.communityAdaptersLoadPromise = null;
          throw err;
        });
    }

    await this.communityAdaptersLoadPromise;
  }

  /**
   * Return adapters title, if there is no such adapter returns 'fallback' if provided
   * or just labelize 'name'.
   */
  getTitle(name: string, fallback?: string): string {
    return this.get(name)?.title || _.defaultTo(fallback, labelize(name));
  }

  getTriggerTitle(name: string, operation: string): string {
    return this.getActionConfig(name, 'trigger', operation)?.title || '';
  }

  requiresConnection(name: string): boolean {
    return this.get(name)?.config?.required === true;
  }

  supportsOauth(name: string): boolean {
    return Boolean(this.get(name)?.config?.oauth);
  }

  supportsCustomOauth(name: string): boolean {
    return !_.isEmpty(this.get(name)?.config?.custom_oauth?.schema);
  }

  supportsDebugTracing(name: Adapter['name']): boolean {
    return Boolean(this.get(name)?.debug_trace_supported);
  }

  getAdapterConfig(name?: string): AdapterConfig | null {
    if (!name) {
      return null;
    }

    return this.get(name)?.config;
  }

  getActionConfig(name?: string, keyword?: string, operation?: string): RawOperation | null {
    if (!name || !keyword || !operation) {
      return null;
    }

    if (keyword === 'trigger') {
      return this.get(name)?.triggers?.[operation];
    } else if (keyword === 'action') {
      return this.get(name)?.actions?.[operation];
    } else {
      return null;
    }
  }

  getOutputSchema(name?: string, keyword?: string, operation?: string): OutputSchemaField[] {
    return this.getActionConfig(name, keyword, operation)?.output ?? [];
  }

  getInputSchema(name?: string, keyword?: string, operation?: string): SchemaField[] {
    return this.getActionConfig(name, keyword, operation)?.input ?? [];
  }

  getAvailableAdapters(): Adapter[] {
    return _(this.withConfig())
      .filter(
        adapter => !(this.oemDisabled(adapter.name) || isDeprecatedAdapter(adapter) || this.delisted(adapter.name)),
      )
      .sortBy(adapter => adapter.title.toLowerCase())
      .value();
  }

  withConfig(): Adapter[] {
    return _.filter(this.adapters, provider => this.requiresConnection(provider.name));
  }

  withCustomOauth(): Adapter[] {
    return _.filter(this.adapters, provider => this.supportsCustomOauth(provider.name));
  }

  withCustomBot(): Adapter[] {
    return _.filter(this.adapters, provider => _.includes(BOT_ADAPTERS_NAMES, provider.name));
  }

  withTriggers(preselected?: Adapter['name']): Adapter[] {
    return _.filter(
      this.adapters,
      provider =>
        Boolean(provider.triggers_count - provider.deprecated_triggers_count) || provider.name === preselected,
    );
  }

  withActions(preselected?: Adapter['name']): Adapter[] {
    return _.filter(
      this.adapters,
      provider => Boolean(provider.actions_count - provider.deprecated_actions_count) || provider.name === preselected,
    );
  }

  withCustomBuildType(): Adapter[] {
    return _.filter(this.adapters, provider => this.custom(provider.name));
  }

  withDemoReleaseType(): Adapter[] {
    return _.filter(this.adapters, provider => this.demo(provider.name));
  }

  builtIn(name: string): boolean {
    return BUILT_IN_ADAPTERS_NAMES.includes(name);
  }

  personalizable(name: string): boolean {
    return this.get(name)?.config?.personalization === true;
  }

  oauthPersonalizable(name: string): boolean {
    return this.get(name)?.config?.oauth_personalization === true;
  }

  custom(name: string): boolean {
    return this.get(name)?.build_type === 'custom';
  }

  demo(name: string): boolean {
    return this.get(name)?.release_type === 'demo';
  }

  triggers(name: string): Operation[] {
    return this.convertRawOperations(this.get(name).triggers);
  }

  actions(name: string): Operation[] {
    return this.convertRawOperations(this.get(name).actions);
  }

  isLoaded(name: string): boolean {
    const adapter = this.get(name);

    if (!adapter) {
      return false;
    }

    return (
      (adapter.actions_count === 0 ||
        (adapter.actions && adapter.actions_count === Object.keys(adapter.actions).length)) &&
      (adapter.triggers_count === 0 ||
        (adapter.triggers && adapter.triggers_count === Object.keys(adapter.triggers).length))
    );
  }

  bulkRegister(adaptersInfo: AdapterInfo[]) {
    const newAdapters = _.filter<AdapterInfo>(
      adaptersInfo,
      adapterInfo => this.isValid(adapterInfo) && !this.get(adapterInfo.name),
    );

    _.forEach(newAdapters, adapter => this.register(adapter));
    this.registerIcons(...newAdapters.map(adapter => adapter.config!));
  }

  update(adapterInfo: AdapterInfo) {
    if (this.isValid(adapterInfo)) {
      this.register(adapterInfo);
    } else {
      delete this.adapters[adapterInfo.name];
    }
  }

  findOperation(adapterName: Adapter['name'], operation: Operation['name']): Operation | undefined {
    return (
      _.find(this.triggers(adapterName), {name: operation}) || _.find(this.actions(adapterName), {name: operation})
    );
  }

  filterSubsets(
    adapters: Array<Adapter | CommunityCertifiedAdapter>,
    filter: string,
  ): Array<Adapter | CommunityCertifiedAdapter> {
    const escapedFilter = _.escapeRegExp(filter);
    const containsFilterRe = new RegExp(escapedFilter, 'i');
    const wordStartsWithFilterRe = new RegExp(`\\b${escapedFilter}`, 'i');
    const containsFilterNotStartsRe = new RegExp(`\\B${escapedFilter}`, 'i');
    const wordStartsWithFilter: Array<Adapter | CommunityCertifiedAdapter> = [];
    const containsFilterNotStarts: Array<Adapter | CommunityCertifiedAdapter> = [];
    const containsFilterInAlias: Array<Adapter | CommunityCertifiedAdapter> = [];

    adapters.forEach(adapter => {
      if (wordStartsWithFilterRe.test(adapter.title)) {
        wordStartsWithFilter.push(adapter);
      } else if (containsFilterNotStartsRe.test(adapter.title)) {
        containsFilterNotStarts.push(adapter);
      } else if (adapter.aliases?.some(alias => containsFilterRe.test(alias))) {
        containsFilterInAlias.push(adapter);
      }
    });

    return [...wordStartsWithFilter, ...containsFilterNotStarts, ...containsFilterInAlias];
  }

  getCssName(name: Adapter['name']): string {
    return name.replace(/[^a-zA-Z0-9-_]/g, '');
  }

  unloadCustomAdapters() {
    this.customAdaptersLoadPromise = null;

    for (const [name, adapter] of Object.entries(this.adapters)) {
      if (this.custom(name)) {
        delete this.adapters[name];
        this.adaptersLoadPromises.delete(name);
        this.customAdaptersInfoLoadPromises.delete(name);
        this.picklistService.resetForAdapter(adapter);
      }
    }

    this.customAdaptersStyleElem?.remove();
    this.customAdaptersStyleElem = undefined;
  }

  hasLowContrastBackground(name: Adapter['name'], theme: ThemeKey): boolean {
    const color = this.get(name)?.color;

    if (!color) {
      return true;
    }

    const lightness = hsl(color).l;

    return theme === 'light' ? lightness > LIGHT_THEME_LIGHTNESS_THRESHOLD : lightness < DARK_THEME_LIGHTNESS_THRESHOLD;
  }

  convertRawOperations(operations: AdapterOperations): Operation[] {
    return _.chain(operations)
      .map((operation: RawOperation, operationName: string) => this.convertRawOperation(operation, operationName))
      .orderBy(['display_priority', 'title'], ['desc', 'asc'])
      .value();
  }

  private convertRawOperation(operation: RawOperation, operationName: string): Operation {
    return {
      name: operationName,
      title: operation.title || operationName,
      description: stripTags(operation.title_hint ?? ''),
      deprecated: Boolean(operation.deprecated),
      realtime: Boolean(operation.realtime),
      batch: Boolean(operation.batch),
      beta: Boolean(operation.beta),
      new: Boolean(operation.new),
      bulk: Boolean(operation.bulk),
      code: Boolean(operation.code),
      file: Boolean(operation.file),
      display_priority: operation.display_priority ?? 0,
    };
  }

  private async loadAdaptersMeta(adapterNames: string[], overwrite = false, params?: AdapterParams): Promise<Adapters> {
    const adapters = await this.integrations.getMeta({adapterNames}, params);

    _.forEach(adapters, (adapter: Adapter, name: string) => {
      if (this.isValid(adapter)) {
        const existingAdapter = this.get(name);

        if (existingAdapter) {
          _.assign(existingAdapter, adapter);
        } else {
          this.adapters[name] = adapter;
          this.registerIcons(adapter);
        }

        this.registerPicklists(adapter, {staging: params?.staging});
      } else if (overwrite) {
        delete this.adapters[name];
      }
    });

    return adapters;
  }

  private registerPicklists(adapter: Adapter, options: PicklistRegisterOptions = {}) {
    if (this.isValid(adapter) && adapter.build_type !== 'unsupported') {
      this.picklistService.resetForAdapter(adapter);

      _.forEach(['triggers', 'actions'], operationType => {
        _.forEach(adapter[operationType], (operation, name) =>
          this.picklistService.register(adapter, name, operation.input, options),
        );
      });
    }
  }

  private isValid(adapter: AdapterInfo | Adapter): boolean {
    if (!adapter) {
      return false;
    }

    return !_.isEmpty(adapter.config) || adapter.build_type === 'unsupported';
  }

  private register(adapterInfo: AdapterInfo) {
    const adapter = adapterInfo.config || ({} as Adapter);

    _.assign(adapter, _.pick(adapterInfo, 'name', 'build_type', 'community_provider', 'release_type', 'categories'));

    this.adapters[adapterInfo.name] = adapter as Adapter;
  }

  private registerIcons(...adapters: AdapterBase[]) {
    const [customAdapters, otherAdapters] = _.chain(adapters)
      .filter(adapter => Boolean(adapter.logo_url))
      .partition(adapter => this.custom(adapter.name))
      .valueOf();

    if (customAdapters.length) {
      this.customAdaptersStyleElem ??= this.createAdaptersStyleElem();

      for (const adapter of customAdapters) {
        this.addStyleForAdapter(adapter, this.customAdaptersStyleElem);
      }
    }

    if (otherAdapters.length) {
      this.otherAdaptersStyleElem ??= this.createAdaptersStyleElem();

      for (const adapter of otherAdapters) {
        this.addStyleForAdapter(adapter, this.otherAdaptersStyleElem);
      }
    }
  }

  private createAdaptersStyleElem(): HTMLStyleElement {
    const elem = document.createElement('style');

    document.getElementsByTagName('head')[0].appendChild(elem);

    return elem;
  }

  private addStyleForAdapter(adapter: AdapterBase, {sheet}: HTMLStyleElement) {
    sheet!.insertRule(
      `.appicon-${this.getCssName(adapter.name)}::after {
          background-image: url(${adapter.logo_url}) !important;
          background-size: 100% !important;
      }`,
      sheet!.cssRules.length,
    );
  }

  private getCategories(adapters?: Adapter[]): string[] {
    return _(adapters ?? this.list())
      .flatMap('categories')
      .compact()
      .uniq()
      .orderBy()
      .value();
  }
}
