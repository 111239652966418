<div class="app-header">
  <div class="app-header__bar app-header__bar_{{ theme }}"></div>
  <div class="app-header__content">
    @if (isMobileLayout$ | async) {
      <button
        class="app-header__sidebar-trigger"
        type="button"
        (click)="openSidebar()"
        wIconButton="layout/burger-menu"
        wIconButtonAriaLabel="Open sidebar"
        wKeyboardNavigationItem
        [wIconButtonIconSize]="20"
      ></button>

      <a class="app-header__logo-link" href="/" wMixpanel="Logo" wKeyboardNavigationItem>
        @if (customLogoTemplate) {
          <ng-container [ngTemplateOutlet]="customLogoTemplate" />
        } @else {
          <w-svg-icon iconId="workato-logo" [size]="[27, 16]" />
        }
      </a>

      <button
        class="app-header__mobile-sidebar-trigger"
        type="button"
        aria-label="Open mobile sidebar"
        (click)="openMobileSidebar()"
        wMixpanel="Open mobile sidebar"
        wKeyboardNavigationItem
      >
        <w-app-context-switcher-icon type="workspace" [value]="currentWorkspace" />
      </button>
    } @else {
      <div class="app-header__items">
        @if (workspaceOptions) {
          <w-app-header-switcher
            class="app-header__item app-header__item_shrinkable"
            ariaLabel="Workspace switcher"
            entityLabel="workspace"
            [maxOptionsHeight]="425"
            [options]="workspaceOptions"
            [selectedValue]="currentWorkspaceId"
            [isServerFilter]="true"
            [loading]="loading"
            (selectedValueChange)="switchWorkspace($event)"
            (queryChange)="queryChanged($event)"
          >
            <ng-template let-item [wAppMenuItemIcon]="workspaceOptions">
              <w-app-context-switcher-icon type="workspace" [value]="item.context" />
            </ng-template>
          </w-app-header-switcher>
        }

        @if (hasProductSwitcher) {
          <hr class="app-header__separator" />

          <w-app-header-switcher
            class="app-header__item"
            ariaLabel="Product switcher"
            [options]="productOptions"
            [selectedValue]="currentProductId"
            (selectedValueChange)="switchProduct($event)"
          >
            <ng-template let-item [wAppMenuItemIcon]="productOptions">
              <w-app-context-switcher-icon type="product" [value]="item.context" />
            </ng-template>
          </w-app-header-switcher>
        }

        @if (hasEnvironmentSwitcher) {
          <hr class="app-header__separator" />

          <w-app-header-switcher
            class="app-header__item"
            ariaLabel="Environment switcher"
            [options]="environmentOptions"
            [selectedValue]="currentEnvironmentId"
            (selectedValueChange)="switchEnvironment($event)"
          >
            <ng-template let-item [wAppMenuItemIcon]="environmentOptions">
              <w-app-context-switcher-icon
                [type]="hasLockedEnvironments ? 'lock' : 'environment'"
                [value]="item.context"
              />
            </ng-template>
          </w-app-header-switcher>
        }
      </div>

      <w-app-header-user class="app-header__aside" />
    }
  </div>
</div>
