import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';

import {KeyboardNavigationItemDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation-item.directive';
import {KeyboardNavigationDirective} from '@shared/modules/keyboard-navigation/keyboard-navigation.directive';

import {SharedModule} from '../../shared.module';
import {AppMenuComponent} from '../app-menu/app-menu.component';
import {AbstractAppMenu} from '../app-menu/abstract-app-menu';
import {AppHeaderDropdownComponent} from '../app-header-dropdown/app-header-dropdown.component';
import {AppMenuItemIconDirective} from '../app-menu-item/app-menu-item-icon.directive';

@Component({
  selector: 'w-app-header-switcher',
  templateUrl: './app-header-switcher.component.html',
  styleUrl: './app-header-switcher.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SharedModule,
    AppMenuComponent,
    AppHeaderDropdownComponent,
    AppMenuItemIconDirective,
    KeyboardNavigationItemDirective,
    KeyboardNavigationDirective,
  ],
})
export class AppHeaderSwitcherComponent<
  TValue = unknown,
  TContext extends object | undefined = undefined,
> extends AbstractAppMenu<TValue, TContext> {
  @Input({required: true}) ariaLabel: string;

  isOpened = false;

  get hasDropdown(): boolean {
    return (this.isServerFilter && Boolean(this.query)) || this.flatOptions.length > 1;
  }
}
