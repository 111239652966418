import {ChangeDetectionStrategy, Component, OnChanges, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';

import {SearchFieldComponent} from '@shared/modules/search-fields/search-field/search-field.component';
import {PluralPipe} from '@shared/pipes/plural.pipe';
import {EmptyModule} from '@shared/components/empty/empty.module';

import {SharedModule} from '../../shared.module';
import {AppMenuItemComponent} from '../app-menu-item/app-menu-item.component';
import {AppMenuItemIconContext} from '../app-menu-item/app-menu-item-icon.directive';
import {WSimpleChanges} from '../../types/angular';

import {AbstractAppMenu} from './abstract-app-menu';
import {AppMenuOption} from './app-menu.types';

const MIN_OPTIONS_FOR_SEARCH = 6;

@Component({
  selector: 'w-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrl: './app-menu.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SharedModule, SearchFieldComponent, AppMenuItemComponent, PluralPipe, EmptyModule],
})
export class AppMenuComponent<TValue = unknown, TContext extends object | undefined = undefined>
  extends AbstractAppMenu<TValue, TContext>
  implements OnInit, OnChanges
{
  filter = '';
  filteredOptions: Array<AppMenuOption<TValue, TContext>> = [];

  ngOnInit(): void {
    if (this.isServerFilter) {
      this.queryChange.emit(this.filter);
    }
  }

  override ngOnChanges(changes: WSimpleChanges<AbstractAppMenu<TValue, TContext>>): void {
    super.ngOnChanges(changes);

    if (changes.options && this.isServerFilter) {
      this.filteredOptions = this.getFilteredOptions(this.filter);
    }
  }

  get hasSearch(): boolean {
    return (this.isServerFilter && Boolean(this.query)) || this.flatOptions.length >= MIN_OPTIONS_FOR_SEARCH;
  }

  get iconTemplate(): TemplateRef<AppMenuItemIconContext<TValue, TContext>> | undefined {
    return this.itemIconTemplateDirective?.templateRef;
  }

  isSelected(option: AppMenuOption<TValue, TContext>): boolean {
    return option.value === this.selectedValue;
  }

  selectOption(option: AppMenuOption<TValue, TContext>) {
    this.selectedValueChange.emit(option.value);
  }

  filterOptions(query: string) {
    this.filter = query;

    if (this.isServerFilter) {
      this.queryChanged(query);
    } else if (query) {
      this.filteredOptions = this.getFilteredOptions(query);
    }
  }

  private getFilteredOptions(query: string): Array<AppMenuOption<TValue, TContext>> {
    if (query) {
      return this.flatOptions.filter(option => option.title.toLowerCase().includes(query.toLowerCase()));
    }

    return [];
  }
}
