import {ChangeDetectorRef, Directive, Input, OnInit, inject} from '@angular/core';
import {startWith, tap} from 'rxjs/operators';
import {BehaviorSubject, merge} from 'rxjs';

import {Overlay} from '@shared/modules/overlay/overlay.service';
import {subscriptions} from '@shared/services/subscriptions';
import {MixpanelService} from '@shared/services/mixpanel';

import {AuthUser, Workspace} from '../../services/auth-user';
import {AppMenuOptions} from '../app-menu/app-menu.types';
import {AccountEnvironment, Environment} from '../../services/account-environments.types';
import {PageUnloadService} from '../../services/page-unload/page-unload.service';
import {WorkspacesService} from '../../services/workspaces.service';
import {AccountEnvironmentService} from '../../services/account-environments.service';
import {SplashScreenComponent} from '../splash-screen/splash-screen.component';

import {AppContextSwitcherService, Product, ProductId} from './app-context-switcher.service';

@Directive({
  standalone: true,
})
export abstract class AbstractAppContextSwitcher implements OnInit {
  @Input() canSwitchWorkspace = true;

  currentWorkspaceId: Workspace['id'];
  currentEnvironmentId: Environment['id'];
  currentProductId: ProductId;

  workspaceOptions: AppMenuOptions<Workspace['id'], Workspace>;
  environmentOptions: AppMenuOptions<Environment['id'], AccountEnvironment>;
  productOptions: AppMenuOptions<ProductId, Product>;

  loading = true;
  workspacesQuery$ = new BehaviorSubject('');

  protected cd = inject(ChangeDetectorRef);
  protected authUser = inject(AuthUser);
  protected overlay = inject(Overlay);
  protected appContextSwitcherService = inject(AppContextSwitcherService);
  protected pageUnloadService = inject(PageUnloadService);
  protected workspacesService = inject(WorkspacesService);
  protected accountEnvironmentService = inject(AccountEnvironmentService);
  protected mixpanelService = inject(MixpanelService);

  protected subs = subscriptions();

  ngOnInit() {
    this.subs.add(
      merge(this.workspacesQuery$, this.authUser.workspacesChange$, this.authUser.currentWorkspaceChange$)
        .pipe(
          startWith(null),
          tap(() => {
            this.loading = true;
            this.cd.markForCheck();
          }),
        )
        .subscribe(async () => {
          this.workspaceOptions = await this.appContextSwitcherService.buildWorkspaceOptions(
            !this.canSwitchWorkspace,
            this.workspacesQuery$.value,
          );
          this.loading = false;

          this.cd.markForCheck();
        }),
    );
    this.environmentOptions = this.appContextSwitcherService.buildEnvironmentOptions();
    this.productOptions = this.appContextSwitcherService.buildProductOptions();

    this.currentWorkspaceId = this.appContextSwitcherService.currentWorkspace.id;
    this.currentEnvironmentId = this.appContextSwitcherService.currentEnvironment.id;
    this.currentProductId = this.appContextSwitcherService.currentProduct.id;
  }

  get hasProductSwitcher(): boolean {
    return this.appContextSwitcherService.hasProductSwitcher;
  }

  get hasEnvironmentSwitcher(): boolean {
    return this.appContextSwitcherService.hasEnvironmentSwitcher;
  }

  get hasLockedEnvironments(): boolean {
    return this.appContextSwitcherService.hasLockedEnvironments;
  }

  get currentWorkspace(): Workspace {
    return this.appContextSwitcherService.currentWorkspace;
  }

  get currentProduct(): Product {
    return this.appContextSwitcherService.currentProduct;
  }

  get currentEnvironment(): AccountEnvironment {
    return this.appContextSwitcherService.currentEnvironment;
  }

  switchWorkspace(id: Workspace['id']) {
    if (id === this.currentWorkspaceId || !this.pageUnloadService.isAllowed({reason: 'teamchange'})) {
      return;
    }

    this.currentWorkspaceId = id;

    const workspace = this.authUser.availableWorkspaces.find(ws => ws.id === id);

    if (workspace) {
      this.pageUnloadService.removeAllHandlers();
      this.workspacesService.showWorkspaceSwitchSlashScreen(workspace.name);
      this.workspacesService.switchToWorkspace(workspace.id, workspace.name, 'Header navigation');
    }
  }

  switchEnvironment(id: Environment['id']) {
    if (id === this.currentEnvironmentId || !this.pageUnloadService.isAllowed({reason: 'envchange'})) {
      return;
    }

    this.currentEnvironmentId = id;

    const environment = this.authUser.available_environments.find(env => env.id === id);

    if (environment) {
      this.pageUnloadService.removeAllHandlers();
      this.overlay.show(SplashScreenComponent, {props: {message: `Switching to ${environment.name} environment.`}});
      this.accountEnvironmentService.switchTo(environment, null, false, 'Header navigation');
    }
  }

  switchProduct(id: ProductId) {
    if (this.currentProductId === id) {
      return;
    }

    this.currentProductId = id;

    this.mixpanelService.track('Switched to product', {product: id}, () => {
      location.replace(
        this.currentProductId === 'agentic' ? this.authUser.agentic_url! : this.authUser.orchestrate_url!,
      );
    });
  }

  queryChanged(query: string) {
    this.workspacesQuery$.next(query);
  }
}
