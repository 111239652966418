import {ContentChild, Directive, EventEmitter, Input, OnChanges, Output} from '@angular/core';

import {WSimpleChanges} from '@shared/types/angular';

import {AppMenuItemIconDirective} from '../app-menu-item/app-menu-item-icon.directive';

import {AppMenuOption, AppMenuOptionGroup, AppMenuOptions} from './app-menu.types';

@Directive()
export abstract class AbstractAppMenu<TValue = unknown, TContext extends object | undefined = undefined>
  implements OnChanges
{
  @Input({required: true}) selectedValue: TValue;
  @Input({required: true}) options: AppMenuOptions<TValue, TContext>;
  @Input() maxOptionsHeight: number | null = null;
  @Input() entityLabel = 'option';
  @Input() isServerFilter = false;
  @Input() loading = false;

  @Output() selectedValueChange = new EventEmitter<TValue>();
  @Output() queryChange = new EventEmitter<string>();

  @ContentChild(AppMenuItemIconDirective) itemIconTemplateDirective?: AppMenuItemIconDirective<TValue, TContext>;

  query = '';
  selectedOption: AppMenuOption<TValue, TContext>;

  protected flatOptions: Array<AppMenuOption<TValue, TContext>>;

  ngOnChanges(changes: WSimpleChanges<AbstractAppMenu<TValue, TContext>>) {
    if (changes.options) {
      this.flatOptions = this.getFlatOptions();
    }

    if (changes.options || changes.selectedValue) {
      this.selectedOption = this.flatOptions.find(option => option.value === this.selectedValue)!;
    }
  }

  hasGroupedOptions(): this is Omit<AbstractAppMenu<TValue, TContext>, 'options'> & {
    options: Array<AppMenuOptionGroup<TValue, TContext>>;
  } {
    return Boolean((this.options as Array<AppMenuOptionGroup<TValue, TContext>>)[0].options);
  }

  hasStandaloneOptions(): this is Omit<AbstractAppMenu<TValue, TContext>, 'options'> & {
    options: Array<AppMenuOption<TValue, TContext>>;
  } {
    return !this.hasGroupedOptions();
  }

  queryChanged(query: string) {
    this.query = query;

    this.queryChange.emit(query);
  }

  private getFlatOptions(): Array<AppMenuOption<TValue, TContext>> {
    if (this.hasGroupedOptions()) {
      return this.options.flatMap(group => group.options);
    }

    if (this.hasStandaloneOptions()) {
      return this.options;
    }

    return [];
  }
}
