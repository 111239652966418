<div class="app-menu">
  @if (hasSearch) {
    <w-search-field
      class="app-menu__search"
      placeholder="Search"
      [value]="filter"
      size="small"
      [autocomplete]="false"
      [inputDebounce]="300"
      (valueChange)="filterOptions($event)"
    />
  }

  @if (loading) {
    <w-spinner class="app-menu__spinner" size="small" />
  } @else {
    <div class="app-menu__items" [style.max-height.px]="maxOptionsHeight">
      @if (filter) {
        @if (!isServerFilter) {
          <div class="app-menu__group-title">{{ filteredOptions.length | wPlural: entityLabel }} found</div>
        }

        @for (option of filteredOptions; track option.value) {
          <w-app-menu-item
            class="app-menu__item"
            [option]="option"
            [highlight]="filter"
            [iconTemplate]="iconTemplate"
            [selected]="isSelected(option)"
            (selectedChange)="selectOption(option)"
          />
        }
      } @else if (hasStandaloneOptions()) {
        @for (option of options; track option.value) {
          <w-app-menu-item
            class="app-menu__item"
            [option]="option"
            [highlight]="filter"
            [iconTemplate]="iconTemplate"
            [selected]="isSelected(option)"
            (selectedChange)="selectOption(option)"
          />
        }
      } @else if (hasGroupedOptions()) {
        @for (group of options; track $index) {
          <div class="app-menu__group-title">{{ group.title }}</div>

          @for (option of group.options; track option.value) {
            <w-app-menu-item
              class="app-menu__item"
              [option]="option"
              [iconTemplate]="iconTemplate"
              [selected]="isSelected(option)"
              (selectedChange)="selectOption(option)"
            />
          }

          @if (!$last) {
            <hr class="app-menu__separator" />
          }
        }
      }
    </div>
  }
</div>
