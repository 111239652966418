import _ from 'lodash';

import {BUILT_IN_ADAPTERS_NAMES} from '../../services/adapters';
import {Adapter, Adapters, AdaptersNames, CommunityCertifiedAdapter} from '../../types';

export interface AdaptersFilteringOptions {
  exclude?: AdaptersNames;
  includeOnly?: AdaptersNames;
  firstOrder?: AdaptersNames;
  showSecondary?: boolean;
}

/**
 * Accepts `apps` as array or object of adapters for further manipulations.
 * Filtering order is the following:
 * 1.) Removes `exclude` adapters (if provided). Also removes secondary adapters (without `showSecondary`)
 * 2.) Removes adapters not in `only` adapters (if provided).
 * 3.) If `firstOrder` provided: puts `firstOrder` adapters first, sorts others by `name`.
 */
export function prepareAdapters(
  apps: Adapters | Array<Adapter | CommunityCertifiedAdapter>,
  opts: AdaptersFilteringOptions,
): Array<Adapter | CommunityCertifiedAdapter> {
  let except = [...BUILT_IN_ADAPTERS_NAMES];
  let only: AdaptersNames = [];

  if (Array.isArray(opts.exclude)) {
    except = except.concat(opts.exclude);
  }

  if (Array.isArray(opts.includeOnly)) {
    only = only.concat(opts.includeOnly);
  }

  let adapters = Object.values(apps);

  if (except.length) {
    const excludedAdapters = new Set(except);

    adapters = adapters.filter(
      adapter =>
        !excludedAdapters.has(adapter.name) &&
        !isDeprecatedAdapter(adapter) &&
        (opts.showSecondary || !isSecondaryAdapter(adapter)),
    );
  }

  if (only.length) {
    const onlyAdapters = new Set(only);

    adapters = adapters.filter(adapter => onlyAdapters.has(adapter.name));
  }

  if (Array.isArray(opts.firstOrder)) {
    const head = _(opts.firstOrder)
      .map(name => adapters.find(adapter => adapter.name === name))
      .compact()
      .value();

    const tail = _(adapters)
      .reject(adapter => head.includes(adapter))
      .sortBy('name')
      .value();

    adapters = head.concat(tail);
  }

  return adapters;
}

export function isSecondaryAdapter(adapter: Adapter | CommunityCertifiedAdapter): boolean {
  return !isCommunityCertified(adapter) && Boolean(adapter.secondary);
}

export function isDeprecatedAdapter(adapter: Adapter | CommunityCertifiedAdapter): boolean {
  return !isCommunityCertified(adapter) && Boolean(adapter.deprecated);
}

export function isCommunityCertified(
  adapter: Adapter | CommunityCertifiedAdapter,
): adapter is CommunityCertifiedAdapter {
  return 'id' in adapter;
}
